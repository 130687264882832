import React, { useEffect, useState } from "react"
import { Button, Typography } from "@mui/material"
import { Container } from "../components/styled"
import { GREY_TEXT } from "../constants"
import useDetectSafari from "../hooks/useDetectSafari"
import styled from "@emotion/styled"

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  margin-right: 40px;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
`

const NotInSafari: React.FC = () => {
  useDetectSafari()

  const handleCopySafariLink = async () => {
    const destination = `${window.location.origin}/enable/first`

    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(destination)
        alert(
          "The link has been copied! Please open Safari and paste the link."
        )
      } catch (err) {
        fallbackCopyLink(destination)
      }
    } else {
      // Fallback for browsers that do not support the Clipboard API (for example iOS Chrome)
      fallbackCopyLink(destination)
    }
  }

  const fallbackCopyLink = (text: string) => {
    const tempInput = document.createElement("textarea")
    tempInput.value = text

    document.body.appendChild(tempInput)
    tempInput.select()

    try {
      const successful = document.execCommand("copy") // Using execCommand for fallback
      if (successful) {
        alert(
          "The link has been copied! Please open Safari and paste the link."
        )
      } else {
        alert(
          `Failed to copy the link. Manually use this link in Safari: ${text}`
        )
      }
    } catch (err) {
      alert(
        `Failed to copy the link. Manually use this link in Safari: ${text}`
      )
    } finally {
      document.body.removeChild(tempInput)
    }
  }

  return (
    <Container>
      <SubContainer>
        <Typography variant="h5">
          Seems like you are not using Safari
        </Typography>
        <Typography variant="subtitle2" color={GREY_TEXT}>
          The Likely extension only works on iOS Safari, please copy the link
          below and open it in Safari
        </Typography>
        <Button
          variant="contained"
          onClick={handleCopySafariLink}
          color="primary"
          size="large"
          sx={{
            borderRadius: "20px",
            marginTop: "50px",
          }}
        >
          Copy link
        </Button>
      </SubContainer>
    </Container>
  );
}

export default NotInSafari

import React, { useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import { Typography } from "@mui/material"
import styled from "@emotion/styled"
import { APP_STORE_LINK } from "../constants"

const logo = require("../images/Likely-logo.png")
const appStore = require("../images/app-store.png")

const Logo = styled.img`
  max-width: 100px;
`

const ImageAppStore = styled.img`
  max-width: 220px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 10vh;
  margin-left: 8vw;
  margin-right: 8vw;
  align-items: center;
`

const LinkToList: React.FC = () => {
  // Using useParams to access the dynamic parts of the URL.
  const { id } = useParams<{ id: string }>()
  const linkPath = `/list/${id}`
  const deepLink = `likely:/${linkPath}`

  useEffect(() => {
    window.location.href = deepLink
  }, [id, deepLink])

  return (
    <Container>
      <Logo src={logo} alt="Likely logo" />
      <Typography variant="h6" textAlign="center">
        This is a link to a list. To use it, open it in a browser on your
        iPhone
      </Typography>
      <Typography
        variant="h6"
        fontWeight="bold"
        textAlign="center"
        sx={{ mt: 3 }}
      >
        Download app to see the list
      </Typography>
      <Link to={APP_STORE_LINK}>
        <ImageAppStore src={appStore} alt="Download on App Store" />
      </Link>
    </Container>
  )
}

export default LinkToList

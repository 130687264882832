// src/App.tsx
import React from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import Home from "./pages/Home"
import EnableExtensionFirst from "./pages/EnableExtensionFirst"
import EnableExtensionSecond from "./pages/EnableExtensionSecond"
import EnableExtensionThird from "./pages/EnableExtensionThird"
import OnboardingSuccess from "./pages/OnboardingSuccess"
import CustomThemeProvider from "./CustomThemeProvider"
import LinkToList from "./pages/LinkToList";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import Contact from "./pages/Contact";
import NotInSafari from "./pages/NotInSafari"

const App: React.FC = () => {
  return (
    <CustomThemeProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/enable/first" element={<EnableExtensionFirst />} />
          <Route path="/enable/second" element={<EnableExtensionSecond />} />
          <Route path="/enable/third" element={<EnableExtensionThird />} />
          <Route path="/re-enable" element={<EnableExtensionThird />} />
          <Route path="/not-in-safari" element={<NotInSafari />} />
          <Route path="/onboarding-success" element={<OnboardingSuccess />} />
          <Route path="/list/:id" element={<LinkToList />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    </CustomThemeProvider>
  )
};

export default App

import React from "react";
import { Button, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { fadeIn } from "../components/styled";

const likelyBig = require("../images/Likely-logo.png");

const StyledContainer = styled.div`
  animation: ${fadeIn} 0.5s ease-in-out;
  position: fixed;
  bottom: 20px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
`;

const Image = styled.img`
  max-width: 150px;
  width: 40%;
  margin-bottom: 15vh; // Responsive margin to maintain spacing
`;

const StyledButton = styled(Button)`
  background-color: #e2001b;
  color: white;
  font-weight: bold;
  font-size: 18px;
  border-radius: 20px;
  width: 90%;
  max-width: 300px;
  height: 50px;
  margin-top: 25px;
  text-transform: none;
  &:hover {
    background-color: #e2001b;
  }
  &:focus {
    background-color: #e2001b;
  }
  &:active {
    background-color: #cc0018;
  }
`;

const SuccessPage: React.FC = () => {
  const handleOpenApp = () => {
    window.location.href = "likely://open";
  };

  return (
    <StyledContainer>
      <Image src={likelyBig} alt="Likely icon" />

      <Typography
        align="center"
        sx={{ fontWeight: "bold", fontSize: "24px", width: "90%" }}
      >
        Last step to get started
      </Typography>
      <Typography
        align="center"
        sx={{
          fontSize: "18px",
          color: "text.secondary",
          marginTop: "5px",
          width: "70%",
        }}
      >
        Sign up to save favorites and create wishlists
      </Typography>
      <StyledButton onClick={handleOpenApp} disableRipple disableElevation>
        Continue
      </StyledButton>
    </StyledContainer>
  );
};

export default SuccessPage;

import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";

const Container = styled.div`
  padding: 16px;
  max-width: 800px;
  margin: 0 auto;
`;

const StyledMarkdown = styled(ReactMarkdown)`
  line-height: 1.6;

  h1 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 2em;
  }

  h2 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 1.5em;
  }

  h3 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 1.25em;
  }

  p {
    margin-bottom: 16px;
  }

  ul {
    margin-bottom: 16px;
    padding-left: 20px;
  }

  li {
    margin-bottom: 8px;
  }

  strong {
    font-weight: 600;
  }
`;

const PrivacyPolicy: React.FC = () => {
  const markdownContent = `
# Introduction
Welcome to Likely's Privacy Policy. We are committed to protecting your privacy and ensuring the security of your information. This policy outlines how we collect, use, and protect your personal data when you use our Likely mobile Safari extension and associated services.

## Data Collection and Use

### Personal Data We Collect:
- **Identifiers**: Such as name and email address when you create an account.
- **Activity Information**: Including products you like, save, and lists you organize.
- **Browser Data**: Pages visited and interactions with products outside of our extension, solely for the purpose of enabling the extension's functionalities.
- **Log Data**: Dates and times of access, pages viewed, links clicked.
- **Communication Data**: Content of your communications with us.
- **Location Information**: Approximate location inferred from your IP address.
- **Usage Data**: Features you use, preferences, interaction with content.

### Purpose of Data Collection:
- To provide, improve, and personalize our services.
- To communicate with you about updates or offers related to Likely.
- To analyze usage patterns and preferences to enhance our extension.

## Legal Basis for Processing:
- **Consent**: You give us consent to process your personal data for specific purposes.
- **Contract Performance**: Processing your data is necessary for the performance of our services that you use or sign up for.
- **Legitimate Interests**: For our legitimate interests in improving our services, provided these do not override your rights and freedoms.
- **Legal Obligations**: To comply with legal obligations to which we are subject.

## Data Sharing and Disclosure
We do not sell your personal data. We may share your information with third-party service providers for the purpose of improving our service, complying with the law, or protecting our rights. We may disclose your personal data to law enforcement agencies or courts when required by law.

## User Rights
You have the right to access, correct, delete, and restrict the processing of your personal data. You can manage your preferences within the Likely extension or by contacting us directly. Your rights include:
- **Right to Access**: Obtain confirmation about whether we process your personal data and access to that data.
- **Right to Rectification**: Request correction of inaccurate or incomplete personal data.
- **Right to Erasure**: Request deletion of your personal data under certain conditions.
- **Right to Restrict Processing**: Request that we limit the processing of your personal data under certain circumstances.
- **Right to Data Portability**: Receive your personal data in a structured, commonly used, and machine-readable format.
- **Right to Object**: Object to the processing of your personal data for certain purposes.
- **Right to Withdraw Consent**: Withdraw your consent at any time where we process your data based on consent.
- **Right to Lodge a Complaint**: Contact a supervisory authority if you believe your privacy rights have been violated.

## Data Security
We take precautions to protect your data from unauthorized access, disclosure, alteration, or destruction. We implement appropriate technical and organizational measures to secure your personal data.

## International Data Transfers
Your data may be stored or processed in countries outside of the EU, under data protection laws that ensure a similar level of protection.

## Data Retention
We retain your personal data only as long as necessary to provide you with our services and as described in this Privacy Policy. We determine retention periods based on criteria such as the duration of your account and our legal obligations.

## Third-Party Links and Services
Our services may contain links to external websites or services not operated by Likely. We are not responsible for the content or privacy practices of these third parties.

## Contact Information
For questions or concerns about our privacy practices, please contact us at:

Likely Technologies AB  
Hägerstensvägen 203  
Hägersten, Sweden

Email: contact@joinlikely.com

## Policy Updates
We may update this policy from time to time to reflect changes in our practices or relevant laws. We will notify you of any significant changes.

**Effective Date**: 2024-09-23
`;

  return (
    <Container>
      <StyledMarkdown>{markdownContent}</StyledMarkdown>
    </Container>
  );
};

export default PrivacyPolicy;
